//< Imports
  import './style.css';
  import {Map, View} from 'ol';
  import TileLayer from 'ol/layer/Tile';
  import OSM from 'ol/source/OSM';
  import {useGeographic} from 'ol/proj.js';
  import ZoomSlider from 'ol/control/ZoomSlider.js';
  import {defaults as defaultControls} from 'ol/control.js';
  import Feature from 'ol/Feature.js';
  import Point from 'ol/geom/Point.js';
  import {Icon, Style} from 'ol/style.js';
  import Overlay from 'ol/Overlay.js';
  import Polygon from 'ol/geom/Polygon.js';
  import Stroke from 'ol/style/Stroke.js';
  import Fill from 'ol/style/Fill.js';
  import {OGCMapTile, Vector as VectorSource} from 'ol/source.js';
  import {Vector as VectorLayer} from 'ol/layer.js';
//>

//< Constants & Setup
  useGeographic();

  const hackNameAndAddress =
    'HackPGH' + '\n \n' +
    '1936 Fifth Ave' + '\n' +
    'Pittsburgh, PA 15219';

  const hackCoordinates = [-79.9777758,
        40.4379489];

  const hackBuildingHighlight = [
    [ -79.9777839, 40.4378265 ], [ -79.9777758, 40.4379489 ], [ -79.9780875,
40.4379615 ], [ -79.978095, 40.4378367 ], [ -79.9777839, 40.4378265 ]
  ];

  const hackAddressHiderCoords = [
    [ -79.9779943, 40.4379716 ], [ -79.977997, 40.437843 ], [ -79.9778121, 40.437843
], [ -79.9778041, 40.4379573 ], [ -79.9779943, 40.4379716 ]
  ];

  const hackPoint = new Point(hackCoordinates);
//>

//< Hack Logo Pin
  const hackPin = new Feature({
    geometry: hackPoint,
    name: hackNameAndAddress,
    label: hackNameAndAddress,
  });

  const hackPinStyle = new Style({
    image: new Icon({
      anchor: [0, 1],
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction',
      src: 'data/hack_pin.svg',
    }),
  });

  hackPin.setStyle(hackPinStyle);

  //< Create Hack Pin vector layer
    const hackPinVectorSource = new VectorSource({
      features: [hackPin],
    });
    const hackPinVectorLayer = new VectorLayer({
      source: hackPinVectorSource,
    });
  //>
//>

//< Parking Lot

  const hackBuildingFeature = new Feature({
    geometry: new Polygon([hackBuildingHighlight]),
    name: hackNameAndAddress,
  })

  const hackAddressHiderFeature = new Feature({
    geometry: new Polygon([hackAddressHiderCoords]),
    name: hackNameAndAddress,
  })

  const parkingLot = new Feature({
    geometry: new Polygon([[
      [ -79.9782244, 40.437904 ], [ -79.9782278, 40.4378428 ], [ -79.9781138,
40.4378387 ], [ -79.9781097, 40.4378999 ],
    ]]),
    labelPoint: new Point([-79.97817235749189, 40.437861009489055]),
    name: 'HackPGH Parking',
  });

  const parkingLotStyle = new Style({
      stroke: new Stroke({
        color: '#4889e5',
        width: 2,
      }),
      fill: new Fill({
        color: 'rgba(72, 137, 229, 0.1)',
      }),
    });

  const buildingStyle = new Style({
      stroke: new Stroke({
        color: '#ffc150',
        width: 2,
      }),
      fill: new Fill({
        color: 'rgba(18, 25, 35, 0.5)',
      }),
    });

    const addressHiderStyle = new Style({
        fill: new Fill({
          color: '#d9d0c9',
        }),
      });

  parkingLot.setStyle(parkingLotStyle);
  hackBuildingFeature.setStyle(buildingStyle);
  hackAddressHiderFeature.setStyle(addressHiderStyle);

  //< Create parking lot vector layer

  const addressHiderVectorSource = new VectorSource({
    features: [hackAddressHiderFeature],
  });

  const addressHiderVectorLayer = new VectorLayer({
    source: addressHiderVectorSource,
  });

    const parkingLotVectorSource = new VectorSource({
      features: [parkingLot, hackBuildingFeature],
    });

    const parkingLotVectorLayer = new VectorLayer({
      source: parkingLotVectorSource,
    });
  //>
//>

//< Create map
  const map = new Map({
    target: document.getElementById('map'),
    layers: [
      new TileLayer({source: new OSM()}),
      addressHiderVectorLayer,
      parkingLotVectorLayer,
      hackPinVectorLayer,
    ],
    view: new View({
      center: hackCoordinates,
      zoom: 18,
      minZoom: 2,
      maxZoom: 20,
    })
  });
//>

//< Hack icon scale

//>

//< Hover Info JS
  const info = document.getElementById('info');

  let currentFeature;
  const displayFeatureInfo = function (pixel, target) {
  const feature = target.closest('.ol-control')
    ? undefined
    : map.forEachFeatureAtPixel(pixel, function (feature) {
        return feature;
      });
  if (feature) {
    info.style.left = pixel[0] + 'px';
    info.style.top =  pixel[1] + 'px';
    if (feature !== currentFeature) {
      info.style.visibility = 'visible';
      info.innerText = feature.get('name');
    }
  } else {
    info.style.visibility = 'hidden';
  }
  currentFeature = feature;
  };

  map.on('pointermove', function (evt) {
  if (evt.dragging) {
    info.style.visibility = 'hidden';
    currentFeature = undefined;
    return;
  }
  const pixel = map.getEventPixel(evt.originalEvent);
  displayFeatureInfo(pixel, evt.originalEvent.target);
  });

  map.on('click', function (evt) {
  displayFeatureInfo(evt.pixel, evt.originalEvent.target);
  });

  map.getTargetElement().addEventListener('pointerleave', function () {
  currentFeature = undefined;
  info.style.visibility = 'hidden';
  });

//>

//< Enable Hack Icon Popup
/*
  const element = document.getElementById('popup');

  const popup = new Overlay({
    element: element,
    positioning: 'center',
    stopEvent: false,
  });
  map.addOverlay(popup);

  let popover;
  function disposePopover() {
    if (popover) {
      popover.dispose();
      popover = undefined;
    }
  }
  */
//>

//< display popup on click
/*
  map.on('click', function (evt) {
    const feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
      return feature;
    });
    disposePopover();
    if (!feature) {
      return;
    }

    const featureCoordinates = feature.getGeometry().getCoordinates();

    popup.setPosition(evt.coordinate);
    popover = new bootstrap.Popover(element, {
      placement: 'top',
      html: true,
      content: feature.get('name'),
    });
    popover.show();
  });

  // change mouse cursor when over marker
  map.on('pointermove', function (e) {
    const pixel = map.getEventPixel(e.originalEvent);
    const hit = map.hasFeatureAtPixel(pixel);
    map.getTarget().style.cursor = hit ? 'pointer' : '';
  });
  // Close the popup when the map is moved
  map.on('movestart', disposePopover);
  */
//>
